import React, { FC } from 'react'
import { Modal as BootstrapModal } from "react-bootstrap"

interface ModalProps {
  title?: string 
  enabled?: boolean
  children?: any
  footer?: any
}

const Modal: FC<ModalProps> = ({ enabled, title = 'Ubook', children, footer }): JSX.Element => 
  <BootstrapModal show={enabled} centered={true} name={title}>
    <BootstrapModal.Header style={{borderBottom: 'none'}}>
      <BootstrapModal.Title>
        { title }
      </BootstrapModal.Title>
    </BootstrapModal.Header>
    
    <BootstrapModal.Body style={{paddingTop:0,paddingBottom:0}}>
      { children }
    </BootstrapModal.Body>
    
    <BootstrapModal.Footer style={{borderTop: 'none', paddingTop: 0}}>
      { footer }
    </BootstrapModal.Footer>
  </BootstrapModal>

export default Modal