import Service from './service';

class UbookCashService extends Service {

    constructor() {
      super();
      super.setNameAndPath("ContentManagerAPI", "user");
    }

    login = async () => {
      return this.doPostRequest('login-ubook-cash', {});
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UbookCashService();
