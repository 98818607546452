import Service from './service';
import localStorageService from './localStorage';

class LanguageService extends Service {

    constructor() {
        super();
        this.api_name = "ContentManagerAPI";
        this.api_path = "languages";
    }

    list = async (storage=false) => {
        
        let languages = localStorageService.loadItem("ubook_languages");

        if ((storage && languages === undefined) || !storage) {
            const languages_api = await this.doGetRequest('list');

            if (languages_api.success && languages_api.languages) {
                languages = languages_api.languages;
                localStorageService.saveItem("ubook_languages", languages);
            }
        }

        return languages;
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new LanguageService();
