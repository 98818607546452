import Service from './service';
import localStorageService from './localStorage';

class CategoryService extends Service {
    constructor() {
        super();
        this.api_name = "ContentManagerAPI";
        this.api_path = "categories";
    }

    list = async (loadFromStorage = false) => {
        let categories = localStorageService.loadItem("ubook_categories");
        let cateories_api = await this.doGetRequest('list');

        if (cateories_api.success && cateories_api.categories) {
            categories = cateories_api.categories;
            localStorageService.saveItem("ubook_categories", categories);
        }

        return categories;
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new CategoryService();
