import Service from './service';

class AuthService extends Service {

    constructor() {
        super();
        this.api_name = "ContentManagerAPI";
        this.api_path = "user";
    }

    loginWithEmailAndPassword = async (email, password, captcha_token) => {
        let login_data = {
            "username": email,
            "password": password,
            "captcha_token": captcha_token
        };
        return this.doPostRequest("signin", login_data);
    }

    signup = async (signup_data) => {
        return this.doPostRequest("signup", signup_data);
    }

    getStudioAuthToken = async () => {
        return this.doGetRequest("get-studio-auth-token");
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new AuthService();
