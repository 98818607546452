import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const SubscriptionPlansEditor = lazy(() => import("app/views/app/subscription-plans/SubscriptionPlansEditor"));

const subscriptionPlans = [
  {
    path: "create",
    component: SubscriptionPlansEditor,
    auth: authRoles.editor
  },
  {
    path: "edit/:id",
    component: SubscriptionPlansEditor,
    auth: authRoles.editor
  }
];

export default subscriptionPlans;
