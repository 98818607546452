// types
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProductService from "app/services/product";
import UserService from "app/services/user";

import CategoryService from "app/services/category";
import LanguageService from "app/services/language";

const initialState = require('../initialState').dashboardInitialState;

export const loadCurrentProduct = createAsyncThunk('dashboard/loadCurrentProduct', (product_id) => {
    return ProductService.getById(product_id);
});

export const checkForNewNotification = createAsyncThunk('dashboard/checkForNewNotification', () => {
    return UserService.checkIfHasNewNotification();
});

export const loadPodcastCategories = createAsyncThunk('dashboard/loadPodcastCagetories', (loadFromStorage = false) => {
    return CategoryService.list(loadFromStorage);
});

export const loadLanguages = createAsyncThunk('dashboard/loadLanguages', (loadFromStorage = false) => {
    return LanguageService.list(loadFromStorage);
});


const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setLeftbarVisibility: (state, action) => {
            state.leftSidebar.hidden = !action.payload;
        },
        setLeftbarSetFixed: (state, action) => {
            state.leftSidebar.fixed = action.payload;
        },
        leftbarToggle: (state) => {
            state.leftSidebar.open = !state.leftSidebar.open;
        },
        unsetCurrentProduct: (state) => {
            state.currentProduct = {...initialState.currentProduct};
        },
        resetDashboard: () => initialState
    },
    extraReducers: (builder) => {
        //checkForNewNotification
        builder.addCase(checkForNewNotification.fulfilled, (state, action) => {
            const response = action.payload;
            if (response?.success) {
                state.notification.hasNew = response.hasNew;
            } else {
                state.notification.error = response?.error;
                state.notification.hasNew = false;
            }
        });
        builder.addCase(checkForNewNotification.rejected, (state, err) => {
            state.notification.hasNew = false;
            console.log('checkForNewNotification.err', err);
        });

        //loadCurrentProduct
        builder.addCase(loadCurrentProduct.pending, (state) => {
            state.currentProduct.loading = true;
        });
        builder.addCase(loadCurrentProduct.fulfilled, (state, action) => {
            const response = action.payload;
            if (response?.success) {
                state.currentProduct.data = response.data.product;
                state.currentProduct.data = response.data.product;
            } else {
                state.currentProduct.error = response?.error;
            }
            state.currentProduct.loading = false;
        });
        builder.addCase(loadCurrentProduct.rejected, (state, err) => {
            console.log(err);
            state.currentProduct.loading = false;
        });

        //loadPodcastCategories
        builder.addCase(loadPodcastCategories.pending, (state) => {
            state.podcastCategories.loading = true;
        });
        builder.addCase(loadPodcastCategories.fulfilled, (state, action) => {
            // const response = action.payload;
            const categories = action.payload;

            let categoryList = {};
            categories.forEach((cat) => {
                let parent_category_id = parseInt(cat['parent_id']);
                let category_id = parseInt(cat['id']);
                let main_category = parent_category_id === 0 ? category_id : parent_category_id;

                //GAMBIARRA: removendo jovem pan e CBN
                if (![192,191].includes(category_id)) {
                    if (typeof categoryList[main_category] == "undefined") {
                        categoryList[main_category] = {
                            "id": main_category,
                            "name": "",
                            "subcategories": []
                        };
                    } else {
                        categoryList[main_category]["name"] = main_category === category_id ? cat['name'].trim() : categoryList[main_category]["name"];
                        if (main_category !== category_id) {
                            categoryList[main_category]["subcategories"].push(cat);
                        }
                    }
                }
            });
            state.podcastCategories.data = categoryList;

            // if (response?.success) {
            //     state.podcastCategories.data = response.data.stores;
            // } else {
            //     state.podcastCategories.error = response?.error;
            // }
            state.podcastCategories.loading = false;
        });
        builder.addCase(loadPodcastCategories.rejected, (state, err) => {
            console.log(err);
            state.podcastCategories.loading = false;
        });

        //loadLanguages
        builder.addCase(loadLanguages.pending, (state) => {
            state.languages.loading = true;
        });
        builder.addCase(loadLanguages.fulfilled, (state, action) => {
            const response = action.payload;
            state.languages.data = response;
            state.languages.loading = false;
        });
        builder.addCase(loadLanguages.rejected, (state, err) => {
            console.log(err);
            state.languages.loading = false;
        });

        // //loadSuppliers
        // builder.addCase(loadSuppliers.pending, (state) => {
        //     state.suppliers.loading = true;
        // });
        // builder.addCase(loadSuppliers.fulfilled, (state, action) => {
        //     const response = action.payload;
        //     if (response?.success) {
        //         state.suppliers.data = response.data.suppliers;
        //     } else {
        //         state.suppliers.error = response?.error;
        //     }
        //     state.suppliers.loading = false;
        // });
        // builder.addCase(loadSuppliers.rejected, (state, err) => {
        //     console.log(err);
        //     state.suppliers.loading = false;
        // });
    }
});

export default dashboard.reducer;

export const {
    setLoading,
    setLeftbarVisibility,
    setLeftbarSetFixed,
    leftbarToggle,
    unsetCurrentProduct,
    resetDashboard
} = dashboard.actions;
