import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";
import { changeAccount } from "app/store/slices/session";
import { Navigate } from "react-router-dom";

const Account = () => {
    const { loggedIn } = useSelector((state) => state.session);

    const dispatch = useDispatch();

	const userAccountsValues = useSelector((state) => state.session.credentials.user_account);
    const selectedAccount = useSelector((state) => state.session.credentials.selectedAccount);
    
    const [initialAccount, setInitialAccount] = useState(selectedAccount);

    useEffect(() => {
        setInitialAccount(selectedAccount);
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    
    function handleSelectAccount (accountId) {
        if (accountId === selectedAccount) {
            //setError('Você já está utilizando essa conta selecionada')
            setTimeout(() => {
                window.location.href='/';
                return;
            }, 2000);
        }
        dispatch(changeAccount(accountId));
    }

    if (selectedAccount !== initialAccount) return (<Navigate to='/' />);
    if (!loggedIn) return (<Navigate to='/signin' />);

    return (
        <div className="auth-layout-wrap">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ubook Studio</title>
            </Helmet>

            <div className="auth-content">
                <div className="auth-logo text-center mb-4 mx-auto">
                    <img src="/assets/images/logo-studio.svg" alt="" />
                </div>
                <div className="card o-hidden">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p-3">
                                <form className="mb-2">
                                    <div className="form-group mt-0 mb-0">
                                        <label htmlFor="account">Selecione uma conta</label>
                                        {userAccountsValues.length !== undefined ? (
                                            userAccountsValues.map((userAccount, idx) => {
                                                return (<input
                                                    className="form-control position-relative dark account-form"
                                                    type="text"
                                                    name="account"
                                                    autoComplete="off"
                                                    onClick={(event) => {
                                                        handleSelectAccount(userAccountsValues[idx].account_id);
                                                    }}
                                                    readOnly="readOnly"
                                                    value={userAccount.account.name}
                                                    key={userAccount.account.id}
                                                />)
                                            })
                                        ) : (
                                            <input
                                                className="form-control position-relative dark account-form"
                                                type="text"
                                                name="account"
                                                autoComplete="off"
                                                onClick={(event) => {
                                                    handleSelectAccount(userAccountsValues.account.id);
                                                }}
                                                readOnly="readOnly"
                                                value={userAccountsValues.account.name}
                                                key={userAccountsValues.account.id}
                                            />
                                        )
                                    }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
