import React, { useEffect, useState } from "react";
import RssService from "app/services/rss";
import utils from "app/services/utils";

const ProductItem = ({ product, changeProduct }) => {
    const [coverImage, setCoverImage] = useState(null);

    useEffect(() => {
        if (product) getCoverImage(product);
    }, [product]);

    const getCoverImage = async (product) => {
        if (product.id) {
            if (product.type !== 'rss') {
                if (product.is_ubook_reference === 'yes') {
                    setCoverImage(product.cover_public_url);
                } else {
                    setCoverImage(utils.buildCoverUrl(product.id, product.cover_image_path, product.type));
                }
            } else if (product.type === "rss") {
                RssService.getFeedCoverImgUrl(product.id).then((response) => {
                    if (response.success) setCoverImage(response.message);
                }).catch((e) => {
                    setCoverImage(RssService.defaultRssImageError());
                });
            }
        }
    };

    return (
        <div onClick={changeProduct} className="header-dropdown-menu-product-item">
            {product != null ? (
                <>
                    <div className="image-wrapper">
                        {coverImage != null && 
                        <img 
                        onError={(e)=>{
                                e.target.onerror = null; 
                                e.target.src= RssService.defaultRssImageError()
                            }
                        }
                        src={coverImage} alt={product.title} />
                        }
                    </div>

                    <div className="product-title">{product.title}</div>
                </>
            ) : (
                <div className="image-wrapper">
                    <img alt="empty"/>
                </div>
            )}
            <div className="clear"></div>
        </div>
    );
};

export default ProductItem;
