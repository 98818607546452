// third-party
import { configureStore } from '@reduxjs/toolkit';

import dashboard from './slices/dashboard';
import session from './slices/session';

import { debounce } from 'debounce';

import initialState from './initialState';

const KEY = 'session';
const loadState = () => {
    try {
        const serializedState = localStorage.getItem(KEY);
        if (!serializedState) {
            return undefined;
        }
        const parsed = JSON.parse(serializedState);

        const merged = {
            ...initialState,
            session: {
                ...initialState.session,
                loggedIn: parsed.session.loggedIn,
                credentials: parsed.session.credentials
            }
        };

        return merged;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

const saveState = async (state) => {
    try {
        const dataToSave = {
            session: {
                loggedIn: state.session.loggedIn,
                credentials: state.session.credentials
            }
        };
        const serializedState = JSON.stringify(dataToSave);
        localStorage.setItem(KEY, serializedState);
    } catch (e) {
        // Ignore
    }
};

const store = configureStore({
    reducer: {
        dashboard,
        session
    },
    preloadedState: loadState()
});

const debounceSaveState = debounce(() => {
    saveState(store.getState());
}, 1000);

store.subscribe(() => {
    debounceSaveState();
});

const { dispatch } = store;

export { store, dispatch };
