import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const PodcastEpisodeEditor = lazy(() => import("app/views/app/podcast/PodcastEpisodeEditor"));
const SubscriptionPlans = lazy(() => import("app/views/app/subscription-plans/SubscriptionPlans"));
const Reports = lazy(() => import("app/views/app/reports/Reports"));
const RSS = lazy(() => import("app/views/app/rss/RSS"));
const SubscriptionPlansEditor = lazy(() => import("app/views/app/subscription-plans/SubscriptionPlansEditor"));
const PodcastEpisode = lazy(() => import("app/views/app/podcast/PodcastEpisode"));
const RssEditor = lazy(() => import("app/views/app/rss/RssEditor"));
const ProductEditor = lazy(() => import("app/views/app/product/ProductEditor"));
const ProductFirstStep = lazy(() => import("app/views/app/creation-hub/ProductFirstStep"));

const product = [
    {
        name: "PodcastDashboard",
        path: ":id",
        component: lazy(() => import("app/views/app/podcast/PodcastDashboard")),
        props: {},
    },
    {
        name: "PodcastEditor",
        path: "create-podcast",
        component: lazy(() => import("app/views/app/podcast/PodcastEditor")),
        props: {},
    },
    {
        name: "PodcastEditor",
        path: ":id/edit",
        component: lazy(() => import("app/views/app/podcast/PodcastEditor")),
        props: {},
    },
    {
        name: "CreationHub",
        path: "creation-hub",
        component: ProductFirstStep,
        props: {},
    },
    {
        name: "CreateAudiobook",
        path: "create-audiobook",
        component: ProductEditor,
        props: {
        type: "audiobook",
        },
    },
    {
        name: "CreateEbook",
        path: "create-ebook",
        component: ProductEditor,
        props: {
            type: "ebook",
        },
    },
    {
        name: "RssEditor",
        path: ":id/edit-rss",
        component: RssEditor,
        props: {},
    },
    {
        name: "AudiobookEditor",
        path: ":id/edit-audiobook",
        component: ProductEditor,
        props: {
            type: 'audiobook'
        },
    },
    {
        name: "EbookEditor",
        path: ":id/edit-ebook",
        component: ProductEditor,
        props: {
            type: 'ebook'
        },
    },
    {
        name: "NewspaperEditor",
        path: ":id/edit-newspaper",
        component: ProductEditor,
        props: {
            type: 'ebook-newspaper'
        },
    },
    {
        name: "PodcastPreferences",
        path: "preferences/:id",
        component: lazy(() => import("app/views/app/podcast/PodcastPreferences")),
        props: {},
    },
    {
        name: "PodcastEpisodeEditor",
        path: ":product_id/episode/create",
        component: PodcastEpisodeEditor,
        props: {},
    },
    {
        name: "PodcastEpisodeEditor",
        path: ":product_id/episode/createPreview",
        component: PodcastEpisodeEditor,
        props: { is_preview: true },
    },
    {
        name: "PodcastEpisodeEditor",
        path: ":product_id/episode/:id/edit",
        component: PodcastEpisodeEditor,
        props: {},
    },
    {
        name: "PodcastSeason",
        path: ":product_id/seasons",
        component: lazy(() => import("app/views/app/podcast/PodcastSeason")),
        props: {},
    },
    {
        name: "PodcastEpisode",
        path: ":product_id/episodes",
        component: PodcastEpisode,
        props: {},
    },
    {
        name: "PodcastListPlans",
        path: ":id/plans",
        component: SubscriptionPlans,
        auth: authRoles.editor,
        props: {},
    },
    {
        path: ":product_id/plan/create",
        component: SubscriptionPlansEditor,
        auth: authRoles.editor,
    },
    {
        path: ":product_id/plan/:id/edit",
        component: SubscriptionPlansEditor,
        auth: authRoles.editor,
    },
    {
        path: ":product_id/reports",
        component: Reports,
        auth: authRoles.editor,
        props: {},
    },
    {
        path: ":product_id/rss",
        component: RSS,
        auth: authRoles.editor,
        props: {},
    }
];

export default product;
