import Service from './service';

class ChapterService extends Service {
    constructor() {
        super();
        this.api_name = "ContentManagerAPI";
        this.api_path = "products";
    }

    list = async (product_id, order = 'latest') => {
        return this.doGetRequest(`chapters/${product_id}/${order}`);
    }

    listByRange = async (data) => {
        return await this.doPostRequest(`list-by-range`, data);
    }

    listByPagination = async (productId, searchTerm, currentPage, itemsPerPage, order, seasonId) => {
        return this.doPostRequest('list-by-pagination', {productId, searchTerm, currentPage, itemsPerPage, order, seasonId });
    }

    save = async (data) => {
        return this.doPostRequest('chapters/save', data);
    }

    updateFileStatus = async (data) => {
        return this.doPostRequest('chapters/update-file-status', data);
    }

    del = async (id) => {
        return this.doDeleteRequest(`chapter/${id}`);
    }

    getById = async (id) => {
        return this.doGetRequest(`chapter/${id}`);
    }

    getFile = async (chapter_id, register_file_type) => {
        let register_type = 'chapter';
        return this.doGetRequest(`files/base64/${register_type}/${chapter_id}/${register_file_type}`);
    }

    hasRestrictChapter = async (product_id) => {
        const response = await this.doGetRequest(`has-restrict-chapter/${product_id}`);
        return response.success;
    }

    hasChapter = async (product_id) => {
        const response = await this.doGetRequest(`has-chapter/${product_id}`);
        return response.success;
    }

    batchSave = async (data) => {
        return await this.doPostRequest(`batch-save`, data);
    }

    batchEditorUpdate = async (data) => {
        return await this.doPostRequest(`batch-editor-update`, data);
    }

    downloadMedia = async (id, mediaType, extraParams = {}) => {
        return this.doGetRequest(`download-media/${id}/${mediaType}`, extraParams);
    }

    setPosition = async (id, action, targetId) => {
        return this.doPostRequest(`set-position`, {id, action, targetId});
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new ChapterService();
