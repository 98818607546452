import React, { Suspense } from "react";

import Loading from "./layout/Loading";
import Router from "./router";

import { Provider } from "react-redux";
import { store } from './store';

import Service from "./services/service";

import "../styles/app/app.scss";

Service.setStore(store);

const App = () => {
    return (
        <Provider store={store}>
            <Suspense fallback={<Loading />}>
                <Router />
            </Suspense>
        </Provider>
    );
};

export default App;
