import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const CreateWithAi = lazy(() => import("app/views/app/create-with-ai/CreateWithAi"));

const product = [
    {
        path: "new",
        component: CreateWithAi,
        auth: authRoles.editor,
        props: {},
    }
];

export default product;
