import Service from './service';
class ProductService extends Service {
    constructor() {
        super();
        this.api_name = "ContentManagerAPI";
        this.api_path = "products";
    }

    canPublishChanges = async (currentProduct) => {
        return currentProduct?.id > 0 && this.IsPublishing(currentProduct) === false && this.WasChanged(currentProduct) && await this.hasPendingAudio(currentProduct?.id) === false;
    }

    list = async (type) => {
        return this.doGetRequest('list');
    }

    listUserProducts = async (searchTerm, currentPage, itemsPerPage) => {
        return this.doPostRequest('list-user-products', {searchTerm, currentPage, itemsPerPage });
    }

    getByType = async (type) => {
        return this.doGetRequest(`byType/${type}`);
    }

    save = async (data) => {
        return this.doPostRequest("save", data);
    }

    update = async (data) => {
        return this.doPostRequest("update", data);
    }

    getById = async (id) => {
        return this.doGetRequest(id);
    }

    getCoverImage = async (id) => {
        let register_type = 'product';
        let register_file_type = 'cover';
        return this.doGetRequest(`files/base64/${register_type}/${id}/${register_file_type}`);
    }

    getEbookFile = async (id) => {
        let register_type = 'product';
        let register_file_type = 'ebook';
        return this.doGetRequest(`files/base64/${register_type}/${id}/${register_file_type}`);
    }

    listEnabledProductsPlans = async (searchTerm, currentPage, itemsPerPage) => {
        return this.doPostRequest('list-enabled-products-plans', {searchTerm, currentPage, itemsPerPage });
    }

    publish = async (id, unlockAllChapters = false) => {
        return this.doPostRequest(`publish/${id}`, {unlockAllChapters});
    }

    hasPlan = async (id) => {
        const response = await this.doGetRequest(`has-plan/${id}`, {});
        return response.success;
    }

    hasActivePlan = async (id) => {
        const response = await this.doGetRequest(`has-active-plan/${id}`, {});
        return response.success;
    }

    hasPendingAudio = async (id) => {
        const response = await this.doGetRequest(`/has-pending-audio/${id}`, {});
        return response.success;
    }

    hasChapter = async (id) => {
        const response = await this.doGetRequest(`has-chapter/${id}`, {});
        return response.success;
    }

    inactivate = async (id) => {
        return this.doPostRequest(`inactivate/${id}`, {});
    }

    saveSeason = async (podcast_id, season) => {
        season.pub_date = new Date(season.pub_date).getTime();
        return this.doPostRequest(`${podcast_id}/season`, season);
    }

    loadSeasons = async (podcast_id) => {
        return this.doGetRequest(`${podcast_id}/season/all`);
    }

    loadCurrency = async () => {
        return this.doGetRequest(`load-currency`);
    }

    loadLibraries = async () => {
        return this.doGetRequest(`load-libraries`);
    }

    LifecycleToText = (product) => {

        if(product == null) {
            return 'Desconhecido';
        }

        if (product.data_changed === 'yes' || product.status_changed === 'yes' || product.chapters_changed === 'yes') {
            return 'Alterado, Aguardando publicação';
        } else {
            switch(product.lifecycle) {
                case 'created':
                case 'reserved':
                    return 'Novo, Aguardando publicação';
                case 'publish_request':
                    return 'Publicando';
                case 'publishing':
                    return 'Publicando';
                case 'published': 
                    return 'Publicado';
                case 'update_request':
                case 'updating':
                    return 'Publicando alterações';
                default:
                    return 'Desconhecido';
            }
        }
    }

    WasChanged(product) {
        if(product == null) {
            return false;
        }
        return product.data_changed === 'yes' || product.status_changed === 'yes' || product.chapters_changed === 'yes';
    }

    WasPublished(product) {

        if(product == null) {
            return false;
        }

        switch(product.lifecycle) {
            case 'created':
            case 'reserved':
            case 'publishing':
            case 'publish_request':
                return false;
            default:
                return true;
        }
    }

    HasLastPublishFailed(product) {

        if(product == null) {
            return false;
        }

        switch(product.lifecycle) {
            case 'update_error':
            case 'add_error':
                return true;
            default:
                return false;
        }
    }

    IsPublishing(product) {

        if(product == null) {
            return false;
        }

        switch(product.lifecycle) {
            case 'publishing':
            case 'publish_request':
            case 'update_request':
            case 'updating':
                return true;
            default:
                return false;
        }
    }

    IsDraft(product) {
        if (product == null) return false;

        switch(product.lifecycle) {
            case 'created':
            case 'reserved':
                return true;
            // case 'published': 
            // case 'updating':
            default:
                return false;
        }
    }

    ChapterTitleByType(product_type, plural = true) {
        switch (product_type) {
            case 'book':
            case 'ebook':
                return plural ? 'Capítulos' : 'Capítulo';
            case 'podcast':
                return plural ? 'Episódios' : 'Episódio';
            //TODO ver o valor correto para cada tipo
            // case 'newspaper':
            // case 'magazine':
            // case 'ebook-magazine':
            // case 'ebook-newspaper':
            // case 'abridgment':
            // case 'ebook-abridgment':
            // case 'uplay':
            // case 'serie':
            // case 'ebook-serie':
            default:
                return plural ? 'Capítulos' : 'Capítulo';
        }
    }

    getRouteByProductType(type) {
        switch (type) {
            case 'book':
            case 'magazine':
                return 'audiobook';
            case 'ebook':
            case 'ebook-magazine':
            case 'ebook-newspaper':
                return  'ebook';
            default:
                return 'podcast';
        }
    }

    getLabelByType(type, plural = false) {
        switch (type) {
            case 'book':
            case 'magazine':
            case 'ebook':
            case 'ebook-magazine':
                return  plural ? 'produtos' : 'produto';
            default:
                return plural ? 'podcasts' : 'podcast';
        }
    }
    
    isEbook(productType) {
        if (productType === undefined || productType === null) return false;
        return productType.includes('ebook');
    }

    isAudio(productType) {
        const defaultTypes = ['book', 'magazine'];
        if (productType === undefined || productType === null) return false;
        return defaultTypes.includes(productType);
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new ProductService();
