import Loading from 'app/layout/Loading';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProductService from "../../services/product";

const Dispatch = () => {
    const session = useSelector((state) => state.session);
    const dashboard = useSelector((state) => state.dashboard);
    const navigate = useNavigate();

    useEffect(() => {
        initialize();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const initialize = async () => {
        try {
            console.log('iniciando dispatch');

            if (dashboard.currentProduct.data) {
                console.log('redirecionar para o produto atual');
                navigate(`/product/${dashboard.currentProduct.data.id}`);
            } else if (session.loggedIn) {
                if (session.credentials.hasManuallySelectedAccount === false) {
                    navigate("/account")
                    return <Loading />;
                }
                
                console.log('redirecionar para o dashboard');
                let path = "/welcome";

                const result = await ProductService.list();

                if (result?.products.length > 0) path = `/product/${result?.products[0].id}`;
                navigate(path);
            } else {
                console.log('dispatch redirecionou para signin');
                navigate('/signin');
            }
        } catch (e) {
            console.log(e);
            //Quando o backend não responde isso está causando um loopinfinito
            // navigate('/signin');
        }
    };

    return <Loading />;
}

export default Dispatch;
