import React from "react";
import { Button } from "react-bootstrap";
import ModalTemplateComponent from "./Modal";
import "./ChapterPublishRestriction.scss"

const ChapterPublishRestriction = ({ show, onClose }) => {
    const GenerateButton = () => {
        return (
            <div className="bttn-general">
                <div className="bttn-1-area">
                    <Button className="bttn-1" onClick={onClose}>FECHAR</Button>
                </div>
            </div>
        );
    };

    const GenerateHeaderTitle = () => {
        return (
            <div> <b>Seu podcast não foi publicado</b></div>
        );
    };

    return (
        <>
            <ModalTemplateComponent enabled={show} title={<GenerateHeaderTitle/>} footer={<GenerateButton />} >
                <center>
                    <p>
                        Cadastre pelo menos 1 conteúdo em seu produto antes de fazer a publicação.
                    </p>
                </center>
            </ModalTemplateComponent>       
        </>
    );
}

export default ChapterPublishRestriction;