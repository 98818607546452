import { API } from 'aws-amplify';
import { logoff } from '../store/slices/session';
class Service {
    static store = null;

    static setStore(store) {
        Service.store = store;
    }

    api_name = '';
    api_path = '';

    setNameAndPath(api_name, api_path) {
        this.api_name = api_name;
        this.api_path = api_path;
    }

    authHeader() {
        const session = Service.store.getState().session;

        if (session.loggedIn) {
            try {
                return { 
                    "X-Account-Studio": session.credentials.selectedAccount,
                    "X-Owner-Account-Studio-Id": session.credentials.accountOwnerId,
                    "Authorization": session.credentials.token,
                    "Content-Type": "application/json"
                };
            }catch (err) {
                console.log(err);
                Service.store.dispatch(logoff());
            }
        } else {
            return { "Content-Type": "application/json" };
        }
    };

    doGetRequest = async (action, extraParams = {}) => {
        let params = { headers: this.authHeader(), ...extraParams };
        let request = API.get(this.api_name, `/${this.api_path}/${action}`, params);
        this.checkLogged(request);
        return request;
    }

    doPutRequest = async (action, data) => {
        let params = { headers: this.authHeader() };
        params['body'] = data;
        let request = API.put(this.api_name, `/${this.api_path}/${action}`, params);
        this.checkLogged(request);
        return request;
    }

    doDeleteRequest = async(action) => {
        let params = { headers: this.authHeader() };
        params['body'] = {};
        let request = API.del(this.api_name, `/${this.api_path}/${action}`, params);
        this.checkLogged(request);
        return request;
    }

    doPostRequest = async (action, data) => {
        let params = { headers: this.authHeader() };
        params['body'] = data;
        let request = API.post(this.api_name, `/${this.api_path}/${action}`, params);
        this.checkLogged(request);
        return request;
    }

    checkLogged = async (request) => {
        request.catch(
            error => {
                if (error?.response?.status !== 403) 
                    return;

                Service.store.dispatch(logoff());
            }
        );
    }
}

export default Service;
