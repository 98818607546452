import React from "react";
import { BrowserRouter, RouteObject, useRoutes } from "react-router-dom";

import * as routes from "./routes";
import AuthenticatedRoute from "./wrappers/AuthenticatedRoute";

import Signin from "app/views/sessions/Signin";
import Signup from "app/views/sessions/Signup";
import Error from "app/views/sessions/Error";
import Welcome from "app/views/app/welcome/welcome";
import UbookStudio from "app/layout/UbookStudio";
import Dispatch from "app/views/app/Dispatch";
import Account from "app/views/app/account/account";
const ContentCreator = React.lazy(() => import("app/views/app/dimo/ContentCreator"));
const ContentCreatorList = React.lazy(() => import("app/views/app/dimo/ContentCreatorList"));
const TmpContentCreator = React.lazy(() => import("app/views/app/dimo/TmpContentCreator"));
const ImportRss = React.lazy(() => import("app/views/app/rss/import-rss/Import"));

const ProductList = React.lazy(() => import("app/views/app/podcast/ProductList"));

const Router = (): JSX.Element => {
    const Routes = () =>
        useRoutes([
            {
                path: "/",
                element: <UbookStudio />,
                children: [
                    { path: "/", index: true, element: <Dispatch /> },
                    { path: "/signin", index: true, element: <Signin /> },
                    { path: "/signup", index: true, element: <Signup /> },
                    { path: "/account", index: true, element: (<Account />),
                    },
                    {
                        path: "/welcome",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                {" "}
                                <Welcome />{" "}
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/dimo-test",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                <TmpContentCreator />
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/content-creator",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                <ContentCreatorList />
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/content-creator-tmp",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                <ContentCreator />
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/products",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                <ProductList />
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/import-rss",
                        index: true,
                        element: (
                            <AuthenticatedRoute>
                                <ImportRss />
                            </AuthenticatedRoute>
                        ),
                    },
                    {
                        path: "/product",
                        children: routes.product.map((route : any) => {
                            const Element = route.component;
                            const props = route?.props;
                            return {
                                index: true,
                                path: `/product/${route.path}`,
                                element: (
                                    <AuthenticatedRoute>
                                        <Element {...props} />
                                    </AuthenticatedRoute>
                                ),
                            };
                        }),
                    },
                    {
                        path: "/create-with-ai",
                        children: routes.createWithAi.map((route : any) => {
                            const Element = route.component;
                            const props = route?.props;
                            return {
                                index: true,
                                path: `/create-with-ai/${route.path}`,
                                element: (
                                    <AuthenticatedRoute>
                                        <Element {...props} />
                                    </AuthenticatedRoute>
                                ),
                            };
                        }),
                    },
                    {
                        path: "/plan",
                        children: routes.subscriptionPlan.map((route) => {
                            const Element = route.component;
                            return {
                                index: true,
                                path: `/plan/${route.path}`,
                                element: (
                                    <AuthenticatedRoute>
                                        <Element />
                                    </AuthenticatedRoute>
                                ),
                            };
                        }),
                    },
                ],
            },
            {
                path: "*",
                element: <UbookStudio />,
                children: [
                    { path: "*", index: true, element: <Error /> }
                ]
            }
        ] as RouteObject[]);

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
};

export default Router;
