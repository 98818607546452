import React from "react";
import { Button } from "react-bootstrap";
import ModalTemplateComponent from "./Modal";
import { useNavigate } from "react-router-dom";
import "./PodcastPublishRestrictContentDialogComponent.scss";

const ProductWarningWithoutMonetization = ({ currentProductId, show, onClose, currentProductType }) => {
  const navigate = useNavigate();

  const getNavigateLocationByType = () => {
    onClose();
    navigate(`/product/${currentProductId}/edit-${currentProductType.includes('newspaper') ? 'newspaper' : 'ebook'}`);
  };

  const getPlanLocation = () => {
    onClose();
    navigate(`/product/${currentProductId}/plans`);
  };

  const GenerateButton = () => (
    <div className="bttn-general">
      <div className="bttn-1-area">
        <Button className="bttn-1" onClick={getPlanLocation} centered="true">
          CONFIGURAR PLANO
        </Button>
      </div>
      <div className="bttn-1-area">
        <Button className="bttn-1" onClick={getNavigateLocationByType}>
          EDITAR MEU PRODUTO
        </Button>
      </div>
    </div>
  );

  const GenerateHeaderTitle = () => (
    <div>
      <b>Você não definiu uma forma de monetização</b>
    </div>
  );

  return (
    <ModalTemplateComponent enabled={show} title={<GenerateHeaderTitle />} footer={<GenerateButton />}>
      <center>
        <p>
          Você marcou seu conteúdo como <b>restrito</b>, mas não definiu uma <b>forma de monetização</b>.
        </p>
      </center>
    </ModalTemplateComponent>
  );
};

export default ProductWarningWithoutMonetization;
