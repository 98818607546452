import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const AuthenticatedRoute = ({ children }) => {
	const {loggedIn } = useSelector((state) => state.session);
	const { hasManuallySelectedAccount} = useSelector((state) => state.session.credentials);

	if (hasManuallySelectedAccount === false) return <Navigate to="/account" />;
	return loggedIn ? children : <Navigate to="/signin" />;
}

export default AuthenticatedRoute;
