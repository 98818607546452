import React from "react";
import "./waitRequestStyle.scss";
import ProgressBar from 'react-bootstrap/ProgressBar';

const WaitRequest = ({ show }) => {
    return (
        <div className="wait-request-component">
            { show ? (<div><div className="wait-request-backdrop"></div><div className="wait-request-pins loader-bubble loader-bubble-danger"></div></div>) : (<div></div>) }
        </div>
    );
};

const WaitUpload = ({ show, percentage }) => {
    return (
        <div className="wait-request-component">
            {show ? (
                <div>
                    <div className="wait-request-backdrop"></div>
                        <div className="centered-progress-bar">
                            Enviando arquivos...
                            <ProgressBar animated now={percentage} label={`${percentage}%`} />
                        </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export {WaitRequest, WaitUpload};
