const sessionInitialState = {
    loading: false,
    loggedIn: false,
    credentials: {
        user: null,
        token: null,
        credentials: null,
        hasManuallySelectedAccount: false,
        accountPermission: null
    },
    login: {
        processing: false,
        failed: false,
        error_message: ''
        // error: {}
    },
    register: {
        processing: false,
        failed: false,
        error_message: ''
        // error: {}
    }
};

const checkOpenSidebarOnInit = () => window.innerWidth >= 1120;

const dashboardInitialState = {
    loading: false,
    notification: {
        hasNew: false,
        error: null
    },
    currentProduct: {
        loading: false,
        data: null,
        error: null
    },
    podcastCategories: {
        loading: false,
        data: null,
        // error: null
    },
    languages: {
        loading: false,
        data: null,
        // error: null
    },
    leftSidebar: {
		open: false,
		hidden: false,
		fixed: checkOpenSidebarOnInit()
	}
};

export { sessionInitialState, dashboardInitialState };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    session: sessionInitialState,
    dashboard: dashboardInitialState
};
