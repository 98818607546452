import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";

if (process.env['REACT_APP_ENV'] === 'local') {
    awsExports.aws_cloud_logic_custom[0].endpoint = "http://localhost:9000";
}

Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
