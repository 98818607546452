import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { leftbarToggle } from "app/store/slices/dashboard";

import { Helmet } from "react-helmet";

import HeaderDropdownMenu from "app/views/component/HeaderDropdownMenu";
import UserNotificationManager from "app/views/component/UserNotificationManager/UserNotificationManager";

import "./UbookStudioHeaderStyle.scss";

import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import ProductService from "app/services/product";
import ChapterService from "app/services/chapter";
import RestrictPodcastModalMessage from "../views/component/Modal/PodcastPublishRestrictContentDialogComponent";
import ProductWarningWithoutMonetization from "../views/component/Modal/ProductWarningWithoutMonetization";
import PodcastFirstPublishModalMessage from "../views/component/Modal/PodcastFirstPublishDialog";
import ChapterPublishRestrictionMessage from "../views/component/Modal/ChapterPublishRestriction";
import { loadCurrentProduct, checkForNewNotification } from '../store/slices/dashboard';

const UbookStudioHeader = () => {
    const dispatch = useDispatch();

    const dashboard = useSelector((state) => state.dashboard);
    const [loadingPublishChangesMenu, setLoadingPublishChangesMenu] = useState(false);
    const [showProductWihtoutMonetizationModal, setShowProductWihtoutMonetizationModal] = useState(false);
    const [showRestrictModal, setShowRestrictModal] = useState(false);
    const [showReservedModal, setShowReservedModal] = useState(false);
    const [showChapterPublishRestrictionModal, setShowChapterPublishRestrictionModal] = useState(false);
    const [podcastHasPlan, setPodcastHasPlan] = useState(false);
    const [unlockChaptersTrigger, setUnlockChaptersTrigger] = useState(false);
    const [publishRequestMessage, setPublishRequestMessage] = useState("");
    const [disabledButton, setDisabledButton] = useState(false);

    const loadNewNotification = async () => {
        dispatch(checkForNewNotification());
    };

    loadNewNotification();

    const checkIfCanPublish = async () => {
        if (dashboard.currentProduct.data) {
            const canpublish = await ProductService.canPublishChanges(dashboard.currentProduct.data);
            if (!canpublish)
                setDisabledButton(true)
        }

    }
    useEffect(() => {
        checkIfCanPublish();
    }, [dashboard.currentProduct.data]) // eslint-disable-line react-hooks/exhaustive-deps

    const handlePublishChangesMenu = async () => {
        setLoadingPublishChangesMenu(true);
        await publishChanges();
        setLoadingPublishChangesMenu(false);
    }

    const publishChanges = async () => {
        let product_id = dashboard.currentProduct.data.id;
        let productType = dashboard.currentProduct.data.type;
        const hasPlan = await ProductService.hasPlan(product_id);

        if (!ProductService.isEbook(productType) && !await ProductService.hasChapter(product_id)) {
            setShowChapterPublishRestrictionModal(true);
            return;
        } else {
            setShowChapterPublishRestrictionModal(false);
        }

        if (!showProductWihtoutMonetizationModal && dashboard.currentProduct.data.business_model_pool === 'no' && dashboard.currentProduct.data.business_model_credit === 'no' && ProductService.isEbook(dashboard.currentProduct.data.type) && !hasPlan) {
            setShowProductWihtoutMonetizationModal(true);
            return;
        } else {
            if ((!showRestrictModal && !unlockChaptersTrigger) && (dashboard.currentProduct.data.business_model_pool === 'no' && await ChapterService.hasRestrictChapter(product_id) && !hasPlan)) {
                setShowRestrictModal(true);
                return;
            } else {
                if (showRestrictModal) {
                    setUnlockChaptersTrigger(true);
                    setShowRestrictModal(false);
                }
            }
        }

        if (showReservedModal === false && dashboard.currentProduct.data.lifecycle === "reserved") {
            setPodcastHasPlan(await ProductService.hasPlan(product_id));
            setShowReservedModal(true);
            return;
        } else if (showReservedModal) {
            //setShowReservedModal(false);
        }
        await ProductService.publish(product_id, unlockChaptersTrigger).then(
            response => {
                if (response.success) {

                    console.log('reload', response);
                    setShowReservedModal(false);
                    dispatch(loadCurrentProduct(product_id));

                } else {

                    if (response.message === 'no_changes_detected') {
                        console.log('no changes detected', response);
                        dispatch(loadCurrentProduct(product_id));
                    } else {
                        console.log('fail to publish', response);
                        setPublishRequestMessage('Houve um problema ao tentar publicar o podcast.');
                    }

                }
            }
        ).catch(
            response => {
                setPublishRequestMessage('[TIMEDOUT] Houve um problema ao tentar publicar o podcast.');
            }
        ).finally(
            response => {
                // props.SetLoadingProduct(false);
            }
        )

        return;
    }

    const handleMenuClick = () => {
        dispatch(leftbarToggle());
    };

    return (
        <div className="main-header ubook-studio-header">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ubook Studio</title>
            </Helmet>

            <div className="menu-toggle" onClick={handleMenuClick}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            <div className="logo">
                <a href="/">
                    <img src="/assets/images/logo-studio.svg" alt="" />
                </a>
            </div>

            <div style={{ margin: "auto" }}></div>

            <div className="header-part-right">
                {dashboard.currentProduct?.data?.id > 0 && dashboard.currentProduct?.data?.type !== 'rss' && dashboard.currentProduct.data?.is_ubook_reference === 'no' && (
                    <>
                        { ((!loadingPublishChangesMenu && !disabledButton) ? (
                            <Button
                                onClick={ () => { handlePublishChangesMenu() }}
                                disabled={loadingPublishChangesMenu === false && disabledButton === false ? false : true}
                                className="mx-1 ubs-bt"
                                variant="secondary"
                            >
                                {loadingPublishChangesMenu && (
                                    <div className="loading" style={{"width":"35px","height":"22px", "marginLeft":"-10px"}}>
                                        <Spinner
                                            animation="border"
                                            style={{
                                                height: 23,
                                                width: 23,
                                                margin: "0 4px 0",
                                                '@media(minWidth: 601px)': {
                                                    margin: '0 auto 0 15px'
                                                }
                                            }}
                                        />
                                    </div>
                                ) }
                                <p className="text-dark pt-0 mb-0">Publicar! <span role="img" aria-label="rocket">🚀</span></p> {/* eslint-disable-line react-hooks/exhaustive-deps */}
                            </Button>
                         ) : "")}
                         {
                            (!ProductService.isEbook(dashboard.currentProduct?.data?.type)) 
                            &&
                            <Button
                                className="mx-1 ubs-bt bg-dark new-ep-header"
                                onClick={() => {window.location.href = `/product/${dashboard.currentProduct.data.id}/episode/create`}} 
                                variant="dark"
                                style={{
                                    border: '1px solid'
                                }}
                            >
                                <span>Novo { ProductService.ChapterTitleByType(dashboard.currentProduct?.data?.type, false) }</span>
                            </Button>
                        }
                    </>
                )}
                <div className="user col px-3 d-flex flex-row">
                    <UserNotificationManager hasNewNotification={dashboard.notification.hasNew} />
                    <HeaderDropdownMenu />
                </div>
            </div>
            {dashboard.currentProduct?.data?.id > 0 && (
                <>
                    <ProductWarningWithoutMonetization 
                        show={showProductWihtoutMonetizationModal}
                        onClose={() => setShowProductWihtoutMonetizationModal(false)}
                        currentProductId={dashboard.currentProduct.data.id}
                        currentProductType={dashboard.currentProduct.data.type}
                    />  

                    <RestrictPodcastModalMessage 
                        show={showRestrictModal}
                        onClose={() => setShowRestrictModal(false)} 
                        onPublish={publishChanges} 
                    />

                    {
                        (!ProductService.isEbook(dashboard.currentProduct?.data?.type)) &&
                        <ChapterPublishRestrictionMessage 
                        show={showChapterPublishRestrictionModal}
                        onClose={() => setShowChapterPublishRestrictionModal(false)}
                        />
                    }

                    <PodcastFirstPublishModalMessage 
                        product_id={dashboard.currentProduct.data.id} 
                        type={dashboard.currentProduct.data.type} 
                        product_cover_image_url={dashboard.currentProduct.data.cover_image_path} 
                        is_pool={dashboard.currentProduct.data.business_model_pool === 'yes' ? true : false} 
                        has_plan={podcastHasPlan}
                        is_free={dashboard.currentProduct.data.business_model_pool === 'yes' ? false : !podcastHasPlan}
                        show={showReservedModal} onClose={() => setShowReservedModal(false)} 
                        onPublish={publishChanges}
                        PublishRequestMessage={publishRequestMessage}
                    />
                </>
            )}
        </div>
    );
}

export default UbookStudioHeader;
