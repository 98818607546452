import React, { useState } from "react";
import { Button, Card, Stack, Container, Row, Col, Spinner } from "react-bootstrap";
import { GoCheck } from 'react-icons/go';
import ModalTemplateComponent from "./Modal";
import "./PodcastFirstPublishDialog.scss";
import utils from "app/services/utils";

const PodcastFirstPublishDialog = ({product_id, type, product_cover_image_url, is_pool, has_plan, is_free, show, onClose, onPublish, PublishRequestMessage}) => {
    const [coverImage, setCoverImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const getCoverImage = async () => {
        console.log(product_cover_image_url, type);
        setCoverImage(await utils.buildCoverUrl(product_id, product_cover_image_url, type));
    }
    
    const publishProduct = async () => {
        setLoading(true);
        await onPublish();
        setLoading(false);
    }

    const GenerateButton = () => {
        return (
            // TODO: a color font do bttn-primary está sendo !important em cima da color font black da classe bttn-1-first-publish e por isso o botão CANCELAR está
            //com fundo totalmente branco
            <>
                {PublishRequestMessage && (<span style={{color: "red"}}>{PublishRequestMessage}</span>)}
                <Stack direction="horizontal" gap={2} style={{paddingTop: '10px', justifyContent:"right"}}>
                    <Button className="bttn-general-first-publish bttn-1-first-publish" onClick={onClose}><p style={{color: "black", margin: "0 0 0"}}>CANCELAR</p></Button>
                    <Button className="bttn-general-first-publish" onClick={publishProduct} disabled={loading}>
                        {loading && (
                            <span className="loading">
                                <Spinner
                                    animation="border"
                                    style={{
                                        height: 16,
                                        width: 16,
                                        marginRight: '5px',
                                        '@media(minWidth: 601px)': {
                                            margin: '0 auto 0 15px'
                                        }
                                    }}
                                />
                            </span>
                        )}
                        CONFIRMAR</Button>
                </Stack>
            </>
        );
    };

    const GenerateCards = () => {
        const productProps = {is_pool, has_plan, is_free};

        return (
            <div>
                {getCardContent(productProps)}
                {<GenerateButton />}
            </div>
        );
    };

    const getCardContent = (productProps) => {
        let cardContentHandler = [];

        Object.keys(productProps).forEach((value) => {
            switch (value) {
                case "is_pool":
                    if (productProps[value]) {
                        cardContentHandler.push({
                            title: 'INCLUÍDO NO ROYALTY-POOL',
                            text: 'Seu conteúdo restrito poderá ser acessado por assinantes Ubook e fará parte da distribuição de receita.'
                        });
                    }
                    break;

                case "has_plan":
                    if (productProps[value]) {
                        cardContentHandler.push({
                            title: 'PLANO PARA MEMBROS',
                            text: 'Seu conteúdo restrito ficará disponível para usuários que se tornarem membros do seu canal.'
                        });
                    }
                    break;

                case "is_free":
                    if (productProps[value]) {
                        cardContentHandler.push({
                            title: 'PODCAST ABERTO',
                            text: 'Seu conteúdo não será monetizado e poderá ser acessado por todos os ouvintes da plataforma.'
                        });
                    }
                    break;
            
                default:
                    break;
            }
        });

        return (
            <Container>
                <Row xs={1} md={2} style={{justifyContent: "center"}}>
                {cardContentHandler.map((props, key) => {
                    return (
                        <Col key={key}>
                            <Card className="m-2">
                                <Card.Body>
                                    <Card.Title style={{ "color": "#A7A7A7", "textTransform": "uppercase", "fontSize": "11px" }}>
                                        <GoCheck size={18} color={'#08d49c'} style={{ 'margin': '0px 0px 2px' }} /> {props.title}
                                    </Card.Title>
                                    
                                    <Card.Text>{props.text}</Card.Text>
                                </Card.Body>  
                            </Card>
                        </Col>
                    );
                })}
                </Row>
            </Container>
        );
    }

    const GenerateHeaderTitle = () => {
        if (coverImage === null) getCoverImage();
        
        return (
            <div className="header-card-general">
                { coverImage != null && (<img className="first-publish-cover" src={coverImage} alt=""/>) }
            </div>
        );
    };

    return (
        <ModalTemplateComponent enabled={show} title={<GenerateHeaderTitle />} footer={<GenerateCards />} >
            <center>
                <h2><b>Seu produto será publicado!</b></h2>
                <p>Dentro de alguns minutos ele estará disponível na plataforma.</p>
                <p style={{marginBottom:0}}><b>Detalhes de monetizacão</b></p>
            </center>
        </ModalTemplateComponent>          
    );
}

export default PodcastFirstPublishDialog;