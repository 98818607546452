import React, { Component } from "react";
import {WaitRequest} from "app/views/component/waitRequest/waitRequest";
import "./style.scss";
import { Link } from 'react-router-dom';

class Welcome extends Component {
    state = {
        showLoader: false
    };

    render() {
        return (
            <div>
                <h1 style={{ marginTop: 12 }}>Bem-vindo ao Ubook Studio</h1>
                <h5>Você está a poucos passos de transformar seu conteúdo em receita recorrente.</h5>

                <div className="welcome-card"></div>

                <ul className="welcome-advantages">
                    <li>
                        <img src="assets/img/welcome-first-icon.webp" alt="" />
                        <div className="text-section">
                            <span className="item-title">Cadastre seu produto e ganhe mais visibilidade</span>
                            <div className="item-desc">
                                Divulgue o seu produto para milhares de ouvintes e acompanhe os seus resultados em tempo real.
                            </div>
                        </div>

                    </li>
                    <li>
                        <img src="assets/img/welcome-second-icon.webp" alt="" />
                        <div className="text-section">
                            <span className="item-title">Monetize seu conteúdo e faça dinheiro</span>
                            <div className="item-desc">
                                Crie um plano de assinatura e receba da sua audiência o incentivo para produzir cada vez mais e melhor.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src="assets/img/welcome-third-icon.webp" alt="" />
                        <div className="text-section">
                            <span className="item-title">Receba e gerencie seu dinheiro no Ubook Cash</span>
                            <div className="item-desc">
                                Você recebe todo o dinheiro na sua conta e pode realizar transações, pagamentos e saques sem taxas.
                            </div>
                        </div>
                    </li>
                    
                    <li className="button">
                        <Link className="mx-1 ubs-bt ubs-bt-organge btn btn-primary mb-4" style={{width: '200px'}} to="/product/creation-hub">COMEÇAR</Link>
                    </li>
                </ul>

                <WaitRequest show={this.state.showLoader}></WaitRequest>
            </div>
        );
    }
}

export default Welcome;
