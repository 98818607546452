import React, { useState, useEffect } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoff } from 'app/store/slices/session';
import { resetDashboard } from 'app/store/slices/dashboard';

import "./style.scss";

import ProductService  from "app/services/product";
import UbookCashService from "app/services/ubookCash";
import ProductItem from "./productItem";

function HeaderDropdownMenu() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboard = useSelector((state) => state.dashboard);
    const selectedAccount = useSelector((state) => state.session.credentials.selectedAccount);
	const userAccounts = useSelector((state) => state.session.credentials.user_account);

    const [state, setState] = useState({
        products: [],
        changed_product: null
    });

    const [loadingUbookcash, setLoadingUbookcash] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const result = await ProductService.list();
            setState({ ...state, products: result.products });
        };
    
        fetchData();
    }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeProduct = (product) => {
        navigate(`/product/${product.id}`);
    };

    const handleMyAccountClick = () => {
        setLoadingUbookcash(true);
        UbookCashService.login()
            .then((res) => {
                if (res.success) {
                    setLoadingUbookcash(false);
                    window.location.href = res.data.login_url;
                } else {
                    console.log(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Dropdown className="header-dropdown-menu-component">
            <Dropdown.Toggle
                as="span"
                className="toggle-hidden cursor-pointer"
            >
            
                { dashboard.loading ? (
                    <span className="header-icon ubook"></span>
                    
                ) : (
                    <div className="current_product">
                        <ProductItem product={dashboard.currentProduct.data} />
                    </div>
                ) }

            </Dropdown.Toggle>

            <Dropdown.Menu>
                { (dashboard.loading === false && dashboard.currentProduct.data !== null )&& (
                    <>
                        <div className="changed_product">
                            <ProductItem product={dashboard.currentProduct.data} />
                        </div>

                        <div className="line"></div>
                    </>
                ) }
        
                { state.products.length > 0 && (
                    <>
                        <div className="product-list">
                            {state.products.slice(0, 3).map( (product, key) => {
                                return (<ProductItem changeProduct={()=>{changeProduct(product)}} key={`product_${key}`} product={product} ></ProductItem>)
                            } )}
                        </div>
                        <Link to="/products" className="dropdown-item">
                            <span className="header-icon add"></span>
                            Meus Produtos
                        </Link>
                    </>
                ) }

                <Link to="/product/creation-hub" className="dropdown-item">
                    <span className="header-icon add"></span>
                    Criar novo produto
                </Link>

                {/* { process.env.REACT_APP_ENV !== 'prod' &&
                    <Link to="/create-with-ai/new" className="dropdown-item">
                        <span className="header-icon add"></span>
                        Criar com IA
                    </Link>
                } */}
                
                <Link to="/import-rss" target="_parent" className="dropdown-item">
                    <span className="header-icon add"></span>
                    Importar feed RSS
                </Link>

                <div className="line"></div>

                <p
                    onClick={handleMyAccountClick}
                    className="dropdown-item cursor-pointer mt-0 mb-0"
                    //style={{"paddingLeft":"3rem","position":"relative","fontWeight":"bold"}}
                >
                    {loadingUbookcash === false ?
                        (<span className="header-icon profile"></span>) :
                        (<div className="loading header-loading-icon">
                            <Spinner
                                animation="border"
                                style={{
                                    height: 23,
                                    width: 23,
                                    margin: "0px 4px 0px 0px"
                                }}
                            />
                        </div>)
                    }
                    Minha conta
                </p>
                {userAccounts.length > 1 && (
                    <Link onClick={() => window.location.href='/account'} className="dropdown-item">
                        <span className="header-icon profile"></span>
                        Trocar de Conta
                    </Link>
                )}
                {/* <Link
                    to="/"
                    className="dropdown-item cursor-pointer"
                >
                    <span className="header-icon help"></span>
                    Ajuda
                </Link> */}
                <Link
                    to="/"
                    className="dropdown-item cursor-pointer"
                    onClick={() => {
                        dispatch(logoff());
                        dispatch(resetDashboard());
                    }}
                >
                    <span className="header-icon logout"></span>
                    Sair
                </Link>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default HeaderDropdownMenu;
