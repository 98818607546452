import React from "react";
import { Button } from "react-bootstrap";
import ModalTemplateComponent from "./Modal";
import "./PodcastPublishRestrictContentDialogComponent.scss"

const PodcastPublishRestrictContentDialogComponent = ({show, onClose, onPublish}) => {
    const GenerateButton = () => {
        return (
            <div className="bttn-general">
                <div className="bttn-1-area">
                    <Button className="bttn-1" onClick={onPublish} centered="true">SIM, ABRIR E PUBLICAR</Button>
                </div>

                <div className="bttn-2-area">
                    <Button className="bttn-2" onClick={onClose}>NÃO, QUERO DEFINIR UMA MONETIZAÇÃO</Button>
                </div>
            </div>
        );
    };

    const GenerateHeaderTitle = () => {
        return (
            <div> <b>Você tem conteúdos restritos</b></div>
        );
    };

    return (
        <>
            <ModalTemplateComponent enabled={show} title={<GenerateHeaderTitle/>} footer={<GenerateButton />} >
                <center>
                    <p>
                        Você marcou um ou mais conteúdos como <b>restritos</b>, mas não definiu uma <b>forma de monetização</b>.
                    </p>

                    <p>
                        Deseja alterar automaticamente todos os conteúdos para <b>abertos</b> e seguir com a publicação?
                    </p>
                </center>
            </ModalTemplateComponent>          
        </>
    );
}

export default PodcastPublishRestrictContentDialogComponent;