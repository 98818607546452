// types
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import AuthService from "../../services/auth";
const initialState = require('../initialState').sessionInitialState;

export const login = createAsyncThunk('creadentials/login', (payload) => {
    return AuthService.loginWithEmailAndPassword(payload.email, payload.password, payload.captcha_token);
});

export const register = createAsyncThunk('creadentials/register', (payload) => {
    return AuthService.signup(payload);
});

const session = createSlice({
    name: 'session',
    initialState,
    reducers: {
        logoff: (state) => {
            state.loggedIn = false;
            state.credentials = {
                hasManuallySelectedAccount: false,
                user: null,
                token: null,
                user_account: null
            };
        },
        changeAccount: (state, action) => {
            state.credentials.hasManuallySelectedAccount = true;
            state.credentials.selectedAccount = action.payload;
        }
    },
    extraReducers: (builder) => {
        //Login
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.login.processing = true;
            state.login.failed = false;
            state.login.error_message = '';
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const response = action.payload;
            try {
                if (response?.success) {
                    //TODO: mover lógica de alteações dos dados após o login para um reducer só para este fim. remover codigo duplicado em register
                    state.loggedIn = true;

                    if (response.user_account.length === undefined || response.user_account.length === 1) {
                        state.credentials.hasManuallySelectedAccount = true;
                    }

                    state.credentials.user = response.user;
                    state.credentials.token = response.user.auth_token;
                    state.credentials.user_account = response.user_account;
                    state.credentials.selectedAccount = response.user_account.length === undefined ? response.user_account.account_id : response.user_account[0].account_id;
                    state.credentials.accountOwnerId = response.account_owner_id;
                    state.credentials.accountPermission = response.account_permission;
                } else {
                    // state.login.error = response?.error;
                    state.login.error_message = response?.msg;
                    state.login.failed = true;
                }
                state.loading = false;
                state.login.processing = false;
            } catch (error) {
                console.log(error);
            }
        });
        builder.addCase(login.rejected, (state) => {
            state.loading = false;
            state.login.failed = true;
            state.login.processing = false;
            state.login.error_message = 'ocorreu um erro processando sua requisição';
        });

        //Register
        builder.addCase(register.pending, (state) => {
            state.loading = true;
            state.register.processing = true;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            const response = action.payload;
            if (response?.success) {
                //TODO: mover lógica de alteações dos dados após o login para um reducer só para este fim. remover codigo duplicado em login
                state.loggedIn = true;
                if (response.user_account.length === undefined || response.user_account.length === 1) {
                    state.credentials.hasManuallySelectedAccount = true;
                }
                state.credentials.user = response.user;
                state.credentials.token = response.user.auth_token;
                state.credentials.user_account = response.user_account;
                state.credentials.selectedAccount = response.user_account.length === undefined ? response.user_account.account_id : response.user_account[0].account_id
                state.credentials.accountOwnerId = response.account_owner_id;
                state.credentials.accountPermission = response.account_permission;
            } else {
                // state.register.error = response?.error;
                state.register.error_message = response?.msg;
                state.register.failed = true;
            }
            state.loading = false;
            state.register.processing = false;
        });
        builder.addCase(register.rejected, (state) => {
            state.loading = false;
            state.register.failed = true;
            state.register.processing = false;
            state.register.error_message = 'ocorreu um erro processando sua requisição';
        });
    }
});

export default session.reducer;

export const {
    logoff,
    changeAccount
} = session.actions;
