class UtilsService {
    constructor() {
        this.ubook_url = "";
        this.url = window.location.href;
    }

    getRssFeedUrl = (env) => {
        switch (env) {
            case "local":
                return "https://localhost:4007/rss/";
            case "dev":
                return "https://7u9qmyg663.execute-api.us-east-1.amazonaws.com/dev/rss/";
            case "featureb":
                return "https://khogvn8j6c.execute-api.us-east-1.amazonaws.com/featureb/rss/";
            case "prod":
                ///return "https://lib9tn0bzb.execute-api.us-east-1.amazonaws.com/prod/rss/";
                return "https://podcast.ubook.com/rss/";
            default:
                return "https://localhost:4007/rss/";
        }
    }

    buildCoverUrl = (id, absoluteCoverImage, type, isUbookRefOptions = null) => {
        const env = process.env.REACT_APP_ENV;
        var baseUrl = '';

        if (isUbookRefOptions !== null && isUbookRefOptions.isUbookRef === 'yes') return isUbookRefOptions.publicUrl;

        switch (env) {
            case "local":
                baseUrl = "https://local-media.studioapp.ubook.com/";
                break;
            case "dev":
                baseUrl = "https://hml-media.studioapp.ubook.com/";
                break;
            case "feature":
            case "featureb":
                baseUrl = "https://feature-media.studioapp.ubook.com/";
                break;
            case "prod":
                baseUrl = "https://media.studioapp.ubook.com/";
                break;
            default:
                baseUrl = "https://local-media.studioapp.ubook.com/";
                break;
        }

        baseUrl += id;

        switch (type) {
            case 'chapter':
                baseUrl += '/chapters/';
                break;
            case 'season':
                baseUrl += '/seasons/';
                break;
            default:
                baseUrl += '/';
                break;
        }

        var RgxMatch = '';

        try {
            RgxMatch = absoluteCoverImage.match(/\/([^/]+)$/);
        } catch (error) {
            RgxMatch = false;
        }

        if (RgxMatch) {
            return baseUrl + RgxMatch[1];
        } else {
            return '';
        }
    }

    getDimoAPIUrl = (env) => {
        switch (env) {
            case 'prod':
                return  "https://api.dimo.ai";
            case 'dev':
            case 'feature':
            case 'featureb':
                return  "https://hml-api.dimo.ai";
            default:
                return  "http://localhost:9001";
        }
    }

    getUbookUrl = (env) => {
        switch (env) {
            case 'prod':
                return  "https://www.ubook.com";
            case 'dev':
            case 'feature':
            case 'featureb':
                return  "https://hml.ubook.com";
            default:
                return  "https://ubook.local";
        }
    }

    loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
        if (!isScriptExist) {
            var script = document.createElement("script")
            script.type = "text/javascript"
            script.src = url
            script.id = id
            script.onload = function () {
                if (callback) callback()
            };
            document.body.appendChild(script)
        }
        if (isScriptExist && callback)
            callback();
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new UtilsService();
