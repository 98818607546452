class localStorageService {

    loadItem = (name) => {
        try {
            const serialized = localStorage.getItem(name);
            if (serialized === null) {
                return undefined;
            }
            return JSON.parse(serialized);
        } catch (err) {
            return undefined;
        }
    };

    saveItem = (name, value) => {
        try {
            const serialized = JSON.stringify(value);
            localStorage.setItem(name, serialized);
        } catch(err) {
            console.log(err);
        }
    }

    removeItem = (name) => {
        localStorage.removeItem(name);
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new localStorageService();
